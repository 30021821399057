<template>
    <b-card body-class="p-0" header-class="h-100" @click="tabCollapse" ref="tabCollapse">
        <template #header>
            <div class="h2 text-center text-lg-left mb-1 mb-md-0" v-html="tab.title"/>
            <div class="text-center text-lg-left mb-3 mb-md-4" v-html="tab.subtitle"/>
            <u-button variant="u-button btn-u-button_blue"
                      size="small"
                      type="button"
                      class-button="px-2 w-100 mt-auto"
                      :text="tab.title"
                      @click="selectTab"
                      v-scroll-to="{ el: '#scroll-from-tab-form', offset: -150}"
            />
        </template>
        <b-list-group flush>
            <b-list-group-item v-for="(row, index) in tab.descriptions" :key="tab.id + index">
                <div class="m-auto">
                    <span v-html="row.option + ':' + '&nbsp;'"/>
                    <span v-html="'<b>' + row.value + '</b>'"/>
                </div>
            </b-list-group-item>
        </b-list-group>
    </b-card>
</template>

<script>
    import TabFormClass from "../../classes/tabForm/";

    export default {
        name: "TabForm",
        components: {
            UButton: () => import(/* webpackChunkName: "u-button" */'../UButton'),
        },
        props: {
            tab: {
                /**
                 * tab type === TabFormClass type (function),
                 * инициализирут деволтный tab как TabFormClass
                 */
                type: TabFormClass,
                default() {
                    return new TabFormClass;
                }
            },
        },
        data() {
            /**
             * @return tabLocal
             */
            return {
                tabLocal: this.tab,
            }
        },
        methods: {
            /**
             * Генерирует событие tab-was-selected,
             * передает в кач-ве аргумента: this.tabLocal и this.tabLocal.id,
             */
            selectTab() {
                this.$root.$emit('tab-was-selected', this.tabLocal, this.tabLocal.id);
            },
            tabCollapse() {
                this.$refs.tabCollapse.classList.toggle('show');
            }
        },
    }
</script>

<style scoped lang="scss">
    .card {
        background: white;
        border: none;
        border-radius: 0;
        @include _md {
            flex: 1 0 100%;
            margin: 0 0 10px 0;
        }

        &-header {
            background: white;
            border: none;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            @include _lg {
                position: relative;

                button {
                    max-width: 135px;
                    margin: 0 auto;
                }

                &:before {
                    content: '';
                    width: 12px;
                    height: 7px;
                    background: url("/img/icon/arrow.svg") no-repeat center;
                    background-size: cover;
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    transition: all .3s ease-out;
                }
            }
        }

        &-body {
            flex: none;
            margin: auto 0 0 0;
            @include _lg {
                .list-group {
                    display: none;
                    opacity: 0;
                    transition: opacity .3s ease-out;
                }
            }
        }

        @include _lg {
            &.show {
                .card {
                    &-header {
                        &:before {
                            transform: rotate(180deg);
                        }
                    }

                    &-body {
                        .list-group {
                            display: block;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
</style>